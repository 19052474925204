<template>
  <div class="three-simultaneous">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row ">
        <el-form-item label="项目名称：" label-width="70px">
          <el-input class="zwx-input" style="height: 30px;width: 310px!important;" placeholder="请输入" v-model.trim="msForm.projectNameLike" clearable></el-input>
        </el-form-item>
        <el-form-item label="批复日期：" label-width="70px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" :picker-options="$validate.noBigDateNoOverToday($data['msForm'], 'approvedDateEnd')" v-model="msForm.approvedDateBegin" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="开始时间" />
          ~
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" :picker-options="$validate.noSmallDateNoOverToday($data['msForm'], 'approvedDateBegin')" v-model="msForm.approvedDateEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="结束时间" />
        </el-form-item>
        <el-form-item label="竣工日期：" label-width="70px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" :picker-options="$validate.noBigDateNoOverToday($data['msForm'], 'completionDateEnd')" v-model="msForm.completionDateBegin" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="开始时间" />
          ~
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" :picker-options="$validate.noSmallDateNoOverToday($data['msForm'], 'completionDateBegin')" v-model="msForm.completionDateEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="结束时间" />
        </el-form-item>
      </div>
      <div class="condition-row ">
        <el-form-item label-width="70px" label="项目性质：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.projectNatureList">
            <el-checkbox class="zwx-checkbox" :label="40120001">新建</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="40120002">改建</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="40120003">扩建</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="40120004">技术改造</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="40120005">技术引进</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="140px" label="职业病危害风险分类：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.hazardRiskList">
            <el-checkbox class="zwx-checkbox" :label="1">严重</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">一般</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryInquire(1)">查询</el-button>
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addDetail">添加</el-button>
        </el-form-item>
      </div>
    </el-form>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%;" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="projectName" label="项目名称" width="300" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="projectNature" label="项目性质" width="120" header-align="center" align="left" :formatter="projectNatureFormatter" show-overflow-tooltip></el-table-column>
      <el-table-column prop="approvedDate" label="批复日期" width="120" header-align="center" align="center"  :formatter="dateFormatter" ></el-table-column>
      <el-table-column prop="completionDate" label="竣工日期" width="120" header-align="center" align="center"  :formatter="dateFormatter" ></el-table-column>
      <el-table-column prop="hazardRisk" label="职业病危害风险分类" width="180" header-align="center" align="center" :formatter="hazardRiskFormatter"></el-table-column>
      <el-table-column fixed="right" label="操作" min-width="200" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-top-right" @click="getDetail(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" @click="deleteByRid(scope.row.rid)"><span style="color: #DD3838;">删除</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <div style="padding: 10px 20px;">
      <base-pagination :parentPage="pageForm.currentPage" :pageSize="pageForm.pageSize" :total="pageForm.total" @currentChange="queryInquire" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThreeSimultaneousMainPage',
  data() {
    return {
      users: this.$store.state.users,
      api: this.$store.state.api,
      employerUuid: null,
      msForm: {
        projectNameLike: '',
        approvedDateBegin: '',
        approvedDateEnd: '',
        completionDateBegin: '',
        completionDateEnd: '',
        projectNatureList: [],
        hazardRiskList: [],
      },
      dataList: [],
      pageForm: {
        currentPage: 1,
        pageSize: 18,
        total: 0,
      },
    }
  },
  computed: {},
  watch: {},
  activated() {
    // 二级页路由跳转回一级页判断是否需要刷新页面
    if (this.$route.params.fresh == true) {
      this.queryInquire(1)
    }
  },
  created() {},
  mounted() {
    let url = this.$route.query
    if (this.$zwxBase.verifyIsNotBlank(url.employerUuid)) {
      this.employerUuid = url.employerUuid
    }
    this.queryInquire(1)
  },
  methods: {
    /**
     * 查询
     */
    queryInquire(currentPage) {
      this.currentPage = currentPage
      let data = {
        employerUuid: this.employerUuid,
        projectNameLike: this.msForm.projectNameLike,
        approvedDateBegin: this.msForm.approvedDateBegin,
        approvedDateEnd: this.msForm.approvedDateEnd,
        completionDateBegin: this.msForm.completionDateBegin,
        completionDateEnd: this.msForm.completionDateEnd,
        projectNature: this.$zwxBase.verifyIsBlank(this.msForm.projectNatureList) ? null : this.msForm.projectNatureList.join(','),
        hazardRiskContent: this.$zwxBase.verifyIsBlank(this.msForm.hazardRiskList) ? null : this.msForm.hazardRiskList.join(','),
        currentPage: currentPage,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/declaration/getThreeSimultaneousRecordList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.dataList = data.threeSimultaneousRecordList
            this.pageForm.total = data.threeSimultaneousRecordCount
            this.pageForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 添加
     */
    addDetail() {
      this.$router.push({ name: 'ThreeSimultaneousEditPage', params: { employerUuid: this.employerUuid } })
    },
    /**
     * 编辑
     */
    getDetail(row) {
      this.$router.push({ name: 'ThreeSimultaneousEditPage', params: { rid: row.rid, employerUuid: this.employerUuid } })
    },
    /**
     * 删除
     */
    deleteByRid(rid) {
      this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
        this.$emit('loading', true)
        let data = { rid: rid }
        this.$system.postJson(
          this.api + '/oh/declaration/deleteThreeSimultaneousRecord-1',
          data,
          true,
          true,
          data => {
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryInquire(1)
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
            this.$emit('loading', false)
          },
          this.error
        )
      })
    },
    /**
     * 项目性质 数据处理
     */
    projectNatureFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '40120001') {
          return '新建'
        } else if (cellValue == '40120002') {
          return '改建'
        } else if (cellValue == '40120003') {
          return '扩建'
        } else if (cellValue == '40120004') {
          return '技术改造'
        } else if (cellValue == '40120005') {
          return '技术引进'
        }
      } else {
        return ''
      }
    },
    /**
     * 职业病危害风险分类 数据处理
     */
    hazardRiskFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '1') {
          return '严重'
        } else if (cellValue == '2') {
          return '一般'
        }
      } else {
        return ''
      }
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
  },
}
</script>

<style lang="less" scoped>

</style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 300px;
}</style>
